import React from 'react'

import Amex from '../../assets/payment/amex.svg'
import ApplePay from '../../assets/payment/applePay.svg'
import GooglePay from '../../assets/payment/googlePay.svg'
import Klarna from '../../assets/payment/klarna.svg'
import Mastercard from '../../assets/payment/mastercard.svg'
import Paypal from '../../assets/payment/paypal.svg'
import Pix from '../../assets/payment/pix.svg'
import Twint from '../../assets/payment/twint.svg'
import Visa from '../../assets/payment/visa.svg'

type Icon = 'Amex' | 'ApplePay' | 'GooglePay' | 'Klarna' | 'Mastercard' | 'Paypal' | 'Twint' | 'Visa' | 'Pix'

const PaymentIcon: React.FC<{ icon: Icon | string | null }> = ({ icon }) => {
  if (!icon) return null

  const width = '34'
  const height = '24'
  const className = 'border rounded-md border-black-hex_20'

  switch (icon) {
    case 'Amex':
      return <Amex width={width} height={height} className={className} />
    case 'ApplePay':
      return <ApplePay width={width} height={height} className={className} />
    case 'GooglePay':
      return <GooglePay width={width} height={height} className={className} />
    case 'Klarna':
      return <Klarna width={width} height={height} className={className} />
    case 'Mastercard':
      return <Mastercard width={width} height={height} className={className} />
    case 'Paypal':
      return <Paypal width={width} height={height} className={className} />
    case 'Twint':
      return <Twint width={width} height={height} className={className} />
    case 'Visa':
      return <Visa width={width} height={height} className={className} />
    case 'Pix':
      return <Pix width={width} height={height} className={className} />
    default:
      return null
  }
}

export default PaymentIcon
