import React from 'react'

import Facebook from '../../assets/social/facebook.svg'
import Instagram from '../../assets/social/instagram.svg'
import Linkedin from '../../assets/social/linkedin.svg'
import Pinterest from '../../assets/social/pinterest.svg'
import Tiktok from '../../assets/social/tiktok.svg'
import Youtube from '../../assets/social/youtube.svg'

type Icon = 'Facebook' | 'Instagram' | 'Linkedin' | 'Pinterest' | 'Tiktok' | 'Youtube'

type Props = {
  icon: Icon | string | null
  width?: number | string
  height?: number | string
  className?: string
}

const SocialIcon: React.FC<Props> = ({ icon, width, height, className }) => {
  if (!icon) return null

  switch (icon) {
    case 'Facebook':
      return <Facebook width={width} height={height} className={className} />
    case 'Instagram':
      return <Instagram width={width} height={height} className={className} />
    case 'Linkedin':
      return <Linkedin width={width} height={height} className={className} />
    case 'Pinterest':
      return <Pinterest width={width} height={height} className={className} />
    case 'Tiktok':
      return <Tiktok width={width} height={height} className={className} />
    case 'Youtube':
      return <Youtube width={width} height={height} className={className} />
    default:
      return null
  }
}

export default SocialIcon
